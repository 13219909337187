
import { defineComponent, ref, onMounted } from "vue"
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router"
import { Actions } from "@/store/enums/StoreEnums"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import $ from 'jquery'
import WelcomeItem from './WelcomeItem.vue'
import DocumentationIcon from './icons/IconDocumentation.vue'
import ToolingIcon from './icons/IconTooling.vue'
import EcosystemIcon from './icons/IconEcosystem.vue'
import CommunityIcon from './icons/IconCommunity.vue'
import SupportIcon from './icons/IconSupport.vue'

export default defineComponent ({
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const goToFolder = () => {
      router.push({ name: 'export_group', params: {id: route.params.eppcm_id} })
    }

    const export_group = ref({} as any)

    store
      .dispatch(Actions.GET_EXPORT_GROUP, route.params.id)
      .then(() => {
        export_group.value = store.getters.currentExportGroup

        if (export_group.value.front_html_id) {
          store
            .dispatch(Actions.GET_HTML, export_group.value.front_html_id)
            .then(() => {
              frontHtml.value = store.getters.currentHtml
              // nextTick(() => {
              //   refIframe.value.contentWindow.postMessage({type: 'init_price', data: html.value.prices}, '*')
              // })

              if (export_group.value.back_html_id) {
                store
                  .dispatch(Actions.GET_HTML, export_group.value.back_html_id)
                  .then(() => {
                    backHtml.value = store.getters.currentHtml
                    // nextTick(() => {
                    //   refIframe.value.contentWindow.postMessage({type: 'init_price', data: html.value.prices}, '*')
                    // })
                  })
                  .catch(() => {
                    // const [error] = Object.keys(store.getters.getErrors);
                    // Swal.fire({
                    //   text: store.getters.getErrors[error],
                    //   icon: "error",
                    //   buttonsStyling: false,
                    //   confirmButtonText: "Try again!",
                    //   customClass: {
                    //     confirmButton: "btn fw-bold btn-light-danger",
                    //   },
                    // });
                  })
              }
            })
            .catch(() => {
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            })
        }
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const htmls = ref([] as any)

    store
      .dispatch(Actions.GET_HTMLS, {eppcm_id: route.params.eppcm_id})
      .then(() => {
        htmls.value = store.getters.currentHtmls
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const currentHtml = ref('front' as any)

    const setFrontHtml = () => {
      currentHtml.value = 'front'
      currentHtmlId.value = export_group.value.front_html_id
    }

    const setBackHtml = () => {
      currentHtml.value = 'back'
      currentHtmlId.value = export_group.value.back_html_id
    }

    const currentHtmlId = ref(41 as any)

    const selectHtml = (item) => {
      console.log(item)
      currentHtmlId.value = item.id
      console.log(currentHtmlId.value)

      // htmls.value.forEach(i => {
      //   if (i.id == item.id) {
      //     i.checked = true  
      //   } else {
      //     i.checked = false
      //   }
      // })

      return true
    }

    const htmlSelected = () => {
      if (currentHtml.value == 'front') {
        export_group.value.front_html_id = currentHtmlId.value

        if (export_group.value.front_html_id) {
          store
            .dispatch(Actions.GET_HTML, export_group.value.front_html_id)
            .then(() => {
              frontHtml.value = store.getters.currentHtml
              // nextTick(() => {
              //   refIframe.value.contentWindow.postMessage({type: 'init_price', data: html.value.prices}, '*')
              // })
            })
            .catch(() => {
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            })
        }
      } else {
        export_group.value.back_html_id = currentHtmlId.value

        if (export_group.value.back_html_id) {
          store
            .dispatch(Actions.GET_HTML, export_group.value.back_html_id)
            .then(() => {
              backHtml.value = store.getters.currentHtml
              // nextTick(() => {
              //   refIframe.value.contentWindow.postMessage({type: 'init_price', data: html.value.prices}, '*')
              // })
            })
            .catch(() => {
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            })
        }
      }
    }

    const save = () => {
      store
        .dispatch(Actions.UPDATE_EXPORT_GROUP, {id: route.params.id, values: {...export_group.value}})
        .then(() => {
          Swal.fire({
            text: "保存成功",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "确认",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          })
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    const frontHtml = ref({} as any)
    const backHtml = ref({} as any)

    return {
      goToFolder,
      export_group,
      htmls,
      setFrontHtml,
      setBackHtml,
      selectHtml,
      currentHtmlId,
      htmlSelected,
      save,
      frontHtml,
      backHtml
    }
  }
})
